<template>
	<div class="home">
		<div class="serch">
			<input placeholder="Know how to become a super agent">
		</div>
		<div class="activity" v-if="activity">
			<router-link :to="{ path: '/activity_info', query: { id: activity.id } }">
				<img :src="host + '/' + activity.img" />
			</router-link>


		</div>
		<div v-if="false" class="swiper">
			<van-swipe :autoplay="3000">
				<van-swipe-item v-for="(item, index) in images" :key="index">
					<img :src="host + '/' + item.img" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="tool-group">

			<div class="tool">
				<router-link to="/deposit">
					<img src="../assets/new/nav_icon1@2x.png" />
					<span>Deposit</span>
				</router-link>
			</div>

			<!--
			<div class="tool">
				<router-link to="/payout">
					<img src="../assets/new/nav_icon2@2x.png" />
					<span>Withdrawn</span>
				</router-link>
			</div>
			-->
			<!--
			<div class="tool">
				<img src="../assets/new/nav_icon3@2x.png" />
				<span>Level</span>
			</div>
			-->
			<div class="tool">
				<router-link to="/help">
					<img src="../assets/new/nav_icon4@2x.png" />
					<span>Help Center</span>
				</router-link>
			</div>
			<!--
			<div class="tool">
				<img src="../assets/new/nav_icon5@2x.png" />
				<span>Service</span>
			</div>

			
			<div class="tool">
				<router-link to="/pro">
					<img src="../assets/new/nav_icon6@2x.png" />
					<span>Bank</span>
				</router-link>
			</div>
			-->
			<div class="tool">
				<router-link to="/direct">
					<img src="../assets/new/nav_icon7@2x.png" />
					<span>Team</span>
				</router-link>
			</div>
			<!--
			<div class="tool">
				<router-link to="/share">
					<img src="../assets/new/nav_icon8@2x.png" />
					<span>Invitation link</span>
				</router-link>
			</div>
			-->
			<div class="tool">
				<router-link to="/channel">
					<img src="../assets/new/nav_icon9@2x.png" />
					<span>Signals</span>
				</router-link>

			</div>
			<div class="tool">
				<router-link to="/activites">
					<img src="../assets/new/nav_icon10.png" />
					<span>Activity</span>
				</router-link>
			</div>




		</div>
		<div class="rewards">
			<div class="rewards-title">New Users Guide</div>

			<div class="rewards-box">

				<div class="reward-left">
					<router-link :to="{ path: '/help_info', query: { id: 1 } }">
						<i>How to become an agent?</i>
						<i>Read for got 50 rewards</i>
					</router-link>
				</div>

				<div class="reward-right">

					<span @click="gohelp(2)">How to keep users funds Safety?

					</span>
					<span @click="gohelp(3)">Know about how {{ plat }} running?</span>
				</div>
			</div>

		</div>


		<div class="agents" v-if="brokers.length > 0">
			<div class="agent-title">New agents<i>More>></i></div>
			<div class="box">

				<div class="agent-item" v-for="( item, index ) in  brokers " :key="index">
					<router-link :to="{ path: '/broker_info', query: { id: item.id } }">
						<img :src="host + '/' + item.img" />
						<div class="agent-info">
							<span>{{ item.member.name }}</span>
							<span>{{ item.member.code }}</span>
						</div>
					</router-link>


				</div>
			</div>


		</div>
		<div class="channel">
			<span class="channel-header">Recommad for you <i>More>></i></span>

			<div class="channel-item" v-for="(item, index) in news" :key="index">
				<router-link v-if="index < 3" :to="{ path: '/news_info', query: { id: item.id } }">
					<div class="channel-contents">
						<div class="title">{{ item.title }}</div>
						<img :src="host + '/' + item.img" />



					</div>
					<div class="channel-type">
						<span>{{ item.new_class.class_title }}</span> {{ item.created_at }}
					</div>
				</router-link>
			</div>






		</div>

		<Tabbar :now="'1'" />
		<van-popup closeable v-model="showPop2" class="notice2">

			<div class="content" v-html="msgs">

			</div>
		</van-popup>
	</div>
</template>

<script>
import "./css/home.scss"
import Tabbar from "../components/tabbar.vue";

import home_function from './js/home'

export default {
	name: "Home",
	components: {
		Tabbar,
	},
	computed: {
		activity: {
			get() {


				return this.$store.state.baseStates.activits[0]
			}
		},
		market_data: {
			get() {
				console.log("设置数据")
				let data = this.$store.state.baseStates.market_data
				let arr = []
				data.forEach(val => {
					arr.push(val)
				})

				return arr

			}
		}
	},

	data() {
		return {
			showPop2: false,

			days: "",
			start_time: "",

			news: [],
			brokers: [],
			market_data2: [],
			info: [],

			agents: [],
			export_time: "",
			images: ["http://127.0.0.1:8099/static/img/1711883942827960.jpeg", "http://127.0.0.1:8099/static/img/1711883942827960.jpeg"]

		};
	},

	created() {



		this.initHomePage();



		//this.activity = store.activitys[0];


	},

	activated() {
		let chat_token = localStorage.getItem("chat_token");
		let _this = this
		this.ws_chat.conn(() => {
			this.ws_chat.sendData({ sub: chat_token })
		})


		this.ws.conn();
		this.ws.Sub(this.marketData, "home");
	},
	methods: {

		...home_function,











	},


};
</script>
