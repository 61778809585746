<template>
    <div class="helps">
        <nav-bar>Help Center</nav-bar>

        <div class="help_class">
            <div @click="select_class(item.id)" :class="{
            ac: select_index == item.id
        }" v-for="(item, index) in classs" :key="index">
                <div class="box">
                    <span><img :src="host + '/' + item.img" /></span>
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>
        <div class="lists">
            <dload-more v-model="total" v-bind:loading="floading" @loadmore="getHlepList">
                <div class="item" v-for="(item, index) in helpList" :key="index">
                    <router-link :to="{ path: '/help_info', query: { id: item.id } }">
                        <h1>{{ item.title }}</h1>
                    </router-link>
                </div>
            </dload-more>
        </div>


    </div>

</template>

<script>
export default {

    data() {
        return {
            total: 0,
            classs: [],
            helpList: [],
            select_index: 0,



            status: 0,
            floading: false
        };
    },
    created() {
        this.getHlepList();
        this.getClassList();

    },

    methods: {
        go() {
            this.$router.push({ path: "/info" })
        },
        select_class(id) {

            this.select_index = id;

            this.get("help/list", { help_type_id: id }).then(res => {
                this.helpList = res.data;
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            });
        },

        getHlepList(page) {


            this.floading = true;
            this.get("help/list", { p: page, help_type_id: 3 }).then(res => {
                this.total = res.total
                this.helpList = this.helpList.concat(res.data)
                console.log(this.floading, "0--")
                this.floading = false;


            });
        },

        getClassList() {


            this.get("help/class_list").then(res => {
                this.classs = res.data;

            });
        },



    }
};
</script>

<style lang="scss" scoped>
.helps {
    height: 100vh;
    overflow-x: hidden;
}

.help_class {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 6rem;

    background: #fff;
    padding: 1rem 1rem;
    display: flex;
    border-bottom: 1px solid #fddddd;
    justify-content: space-evenly;

    div {
        &.ac {
            span {
                color: #fa0404;
                opacity: 0.6;
            }


        }
    }

    span {

        padding-bottom: 1rem;
        text-align: center;
        display: block;

        img {
            width: 4rem;
            height: 4rem;
        }


    }
}

.lists {

    background: #fff;
    height: 90vh;
    overflow-y: scroll;
    padding-top: 13rem;

    .item {
        display: block;
        padding: 0.2rem 2rem;

        h1 {
            color: #969595;
            font-weight: 400;
        }

        border-bottom: 1px solid #f5f5f5;
    }
}
</style>