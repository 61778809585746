<template>
	<div>
		<nav-bar>Wallet withdraw</nav-bar>


		<div class="bank" v-if="bindbank == false">
			<tip>{{ $t('not_bind_bank') }}</tip>
			<router-link to="/bind_card"><base-btn>Bind cash withdrawal bank card</base-btn></router-link>
		</div>

		<div class="safe" v-if="bindbank == true">
			<div class="balance">
				<i>{{ $t('active_balance') }}:</i>
				<i>
					<animated-number class="funds" :value="balance" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>

			<div>
				<i>{{ $t('taxs') }}:</i>
				<i>
					<animated-number class="funds" :value="fee" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>
			<div>
				<i>{{ $t('total_amount') }}:</i>
				<i>
					<animated-number class="funds" :value="total" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>

			<div class="bankitem">

				<van-radio-group v-for="(item, index) in bank" :key="index" v-model="bank_id">
					<van-radio :name="item.id">{{ item.bank_name }} {{ item.bank_account }}</van-radio>
				</van-radio-group>
			</div>

			<div class="tab">
				<div class="title">Select Wallet</div>
				<div class="box">
					<li :class="{ ac: tp == 0 ? true : false }" @click="balance_payout">Binary</li>
					<li :class="{ ac: tp == 1 ? true : false }" @click="sarlry_payout">Salary </li>
					<li :class="{ ac: tp == 2 ? true : false }" @click="sarlry_payout">Rewards</li>
				</div>

			</div>
			<base-form ref="from">
				<div>
					<base-input v-model="amount" maxlength="20" :roule="{ reg: '^[0-9]{3,12}$', msg: 'Invild Amount' }"
						placeholder="Amount"></base-input>
				</div>
			</base-form>


			<div>
				<base-btn :loading="loading" @click="addBank">{{ $t('submit') }}</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
	components: { AnimatedNumber },
	name: "AccountHis",
	data() {
		return {
			feeset: 0,
			loading: false,
			current: 0,
			amount: "",
			fee: 0,
			total: 0,

			bank: [],
			bank_id: 0,
			info: "",
			balance: 0,

			bindbank: false,
			tp: 0,



			wallet: {},
		};
	},
	created() {
		this.getInfo();

		this.getBank();
		this.getFee();
	},
	activated() {
		this.getInfo();

		this.getBank();
	},
	watch: {
		amount(newvol) {
			this.fee = (newvol * this.feeset) / 100;
			this.total = parseFloat(this.fee) + parseFloat(newvol);
		}
	},

	methods: {
		getFee() {
			this.get("sys/config", { key: "payout_fee" }).then(res => {
				this.feeset = res.data.payout_fee;
			});
		},
		getBank() {
			this.get("bank/list").then(res => {

				if (res.data.length == 0) {
					this.bindbank = false;

					return;
				}
				this.bindbank = true;
				this.bank = res.data;
			});
		},
		balance_payout() {
			this.balance = this.wallet.balance;
			this.tp = 0
		},
		sarlry_payout() {
			this.balance = this.wallet.salary;
			this.tp = 1
		},

		getInfo() {
			this.get("user/info").then(res => {

				this.wallet = res.data;
				this.balance_payout();
			});
		},
		formatToPrice(value) {
			return `₹${Number(value).toFixed(2)}`;
		},
		addBank() {
			//console.log(this.bank_id);
			if (this.$refs.from.submit() == false) {
				return;
			}
			let patm = { amount: this.amount, bank_id: this.bank_id, tp: this.tp };
			this.loading = true;
			this.post("payout/create", patm).then(res => {
				this.$toast(res.info);
				this.loading = false;
			});
		},

	}
};
</script>

<style lang="scss" scoped>
.tab {

	padding: 2rem 0;


	background: #fff;

	.box {
		display: flex;
		flex-wrap: wrap;
		font-size: 1.2rem;

		justify-content: space-around;

		li {

			border-radius: 0.5rem;
			background: #fff;

			text-align: center;

			list-style: none;
			padding: 1rem 3rem;
			margin-left: 1rem;
			border: 1px solid #f2f2f2;

			&.ac {
				border: 1px solid #f42703;
				color: #f90303;
			}
		}
	}
}

.kyc,
.bank {
	background: #fff;
	padding: 2rem;

}

.safe {
	padding: 1rem;
	height: 90vh;
	background: #fff;

	.funds {
		font-size: 2.5rem;
		color: #555555;
		font-weight: normal;
	}

	.balance {
		display: block;
		border-bottom: 1px solid #f2f2f2;
		padding: 2rem 0;
	}

	.bankitem {
		display: block;
		border: 1px dashed #555555;
		border-radius: 1rem;
		padding: 2rem 0;


	}

	div {
		i {
			font-style: normal;

			&:first-child {
				color: #949292;
			}

			&:last-child {
				font-weight: bold;
				font-size: 3rem;
			}
		}

		margin: 1rem;
	}
}
</style>