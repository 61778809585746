<template>
	<div>
		<nav-bar>Update bank information</nav-bar>

		<div class="safe">
			<van-notice-bar wrapable :scrollable="false" color="#1989fa" background="#ecf9ff" left-icon="info-o">The
				same
				account can only be bound to one account</van-notice-bar>
			<base-form ref="from">
				<div>
					<base-input v-model="from.account_holder" maxlength="20"
						:roule="{ reg: '^(.*){3,20}$', msg: 'Invild Bank Account holder' }"
						placeholder="Bank Account holder"></base-input>
				</div>
				<div>
					<base-input v-model="from.bank_account" maxlength="20"
						:roule="{ reg: '^[0-9]{5,20}$', msg: 'Invild Bank Account' }"
						placeholder="Bank Account"></base-input>
				</div>
				<div>
					<base-input v-model="from.ifsc" maxlength="11"
						:roule="{ reg: '^[a-z,A-Z,0-9]{11}$', msg: 'Invild IFSC' }" placeholder="IFSC"></base-input>
				</div>
				<div>
					<base-input v-model="from.bank_name" maxlength="20"
						:roule="{ reg: '^[a-z,A-Z,\\s]{3,20}$', msg: 'Invild Bank Name' }"
						placeholder="Bank Name"></base-input>
				</div>
				<div>
					<base-input v-model="from.code" maxlength="6" :roule="{ reg: '^[0-9]{6}$', msg: 'Invild OTP' }"
						placeholder="OTP"></base-input>
				</div>
			</base-form>
			<div>
				<base-btn v-show="sta == 0" :loading="loading" @click="otp">Send OTP</base-btn>
			</div>
			<div>
				<base-btn v-show="sta == 1" :loading="loading" @click="addBank">Submit</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			loading: false,
			current: 0,
			sta: 0,
			bank: "",
			from: {
				id: "",
				bank_account: "",
				account_holder: "",
				ifsc: "",
				bank_name: ""
			}
		};
	},
	created() {
		this.getBank();
	},
	mounted() { },
	methods: {
		getBank() {
			this.get("bank/list").then(res => {
				console.log(res.data.length);
				if (res.data.length == 0) {
					Dialog.alert({
						message: "Please bind the withdrawal bank card first"
					}).then(() => {
						this.$router.push("/bind_card");
					});
					return;
				}
				this.from.bank_account = res.data[0].bank_account;
				this.from.ifsc = res.data[0].ifsc;
				this.from.bank_name = res.data[0].bank_name;
				this.from.account_holder = res.data[0].account_holder
				this.from.id = res.data[0].id
				//this.bank = res.data[0];
			});
		},
		otp() {

			this.post("bank/otp").then(res => {
				if (res.status == 1) {
					this.sta = 1
				}
				this.$toast(res.info);
				this.loading = false;
			});

		},
		addBank() {
			if (this.$refs.from.submit() == false) {
				return;
			}
			this.loading = true;
			this.post("bank/update", this.from).then(res => {
				this.$toast(res.info);
				this.loading = false;
			});
		},

		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.safe {
	padding: 1rem;
	height: 90vh;
	background: #fff;

	div {
		margin: 1rem;
	}
}
</style>