<template>
	<div class="login-sign">
		<div class="close"><router-link to="/login"><img src="../assets/bit/x.png" /></router-link></div>
		<div class="logo">
			<h1>{{ $t('create_account') }}</h1>
			<h2>{{ $t('get_free_account') }}</h2>
		</div>
		<div class="step">
			<div :class="{ ac: step == 0 ? true : false }" @click="gotostep(0)"></div>
			<div :class="{ ac: step == 1 ? true : false }" @click="gotostep(1)"></div>
			<div :class="{ ac: step == 2 ? true : false }" @click="gotostep(2)"></div>
		</div>
		<div class="box">
			<div class="login">
				<div v-show="step == 0">
					<input @blur="checkpass" type="text" v-model="login_form.name" :placeholder="$t('name')" />
					<input @blur="checkpass" v-model="login_form.email" type="email" :placeholder="$t('email')" />
					<input @keyup="checkpass" v-model="login_form.pass_word" type="password"
						:placeholder="$t('pass')" />
				</div>
				<div v-show="step == 1">

					<input @blur="checkcode" v-model="login_form.mob" type="number" :placeholder="$t('mob')" />
					<input @keyup="checkcode" v-model="login_form.invite_code" type="number"
						:placeholder="$t('ref_code')" />
				</div>
				<div v-show="step == 2">


					<input @keyup="check_otp" v-model="login_form.ver_code" type="text" :placeholder="$t('otp')" />
				</div>
				<base-btn :dis="dis" :loading="loading" @click="signin">{{ btn_text }}</base-btn>

				<div v-show="step == 2" class="login-btn ac" @click="checkotp">{{ $t('resend') }}</div>
				<div class="create_account">
					{{ $t('have_account') }}?
					<router-link to="/login"><a>{{ $t('login') }}</a></router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Dialog } from "vant";
import input from "../components/input.vue";
import Fingerprint2 from "fingerprintjs2";
export default {
	components: { input },
	name: "SignUp",
	data() {
		return {
			loading: false,
			btn_text: "Continue",
			step: 0,
			login_form: {
				email: "",
				name: "",
				mob: "",
				pass_word: "",
				finger_reg: "",
				invite_code: "",
				ver_code: "",
				invite_code: ""
			},
			otp: false,

			has_err: false,
			dis: true,
			token: ""


		};
	},
	created() {

		setTimeout(this.getInger(), 500);
		this.btn_text = this.$t('continue')

		let token = localStorage.getItem("token");
		if (token != null) {
			this.$router.push("/home");
		}
		if (localStorage.getItem("mob") != null) {
			this.old = true;
			this.login_form.mob = localStorage.getItem("mob");
		}


	},
	activated() {


	},
	methods: {

		getInger() {

			let option = {
				fonts: {
					extendedJsFonts: true,
				},
				excludes: {
					language: true,
					colorDepth: true,
					deviceMemory: true,
					pixelRatio: true,
					availableScreenResolution: true,
					timezoneOffset: true,
					timezone: true,
					sessionStorage: true,
					localStorage: true,
					indexedDb: true,
					addBehavior: true,
					openDatabase: true,
					cpuClass: true,
					doNotTrack: true,
					plugins: true,
					canvas: true,
					webglVendorAndRenderer: true,
					adBlock: true,
					hasLiedLanguages: true,
					hasLiedResolution: true,
					hasLiedOs: true,
					hasLiedBrowser: true,
					touchSupport: true,
					audio: true,
					enumerateDevices: true,
					hardwareConcurrency: true
				}
			};

			let _this = this;
			Fingerprint2.get(option, function (components) {
				const values = components.map(function (component, index) {
					if (index === 0) {
						//把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
						return component.value.replace(/\bNetType\/\w+\b/, "");
					}
					return component.value;
				});
				// 生成最终id murmur
				const murmur = Fingerprint2.x64hash128(values.join(""), 31);
				console.log(murmur);
				_this.login_form.finger_reg = murmur;

			});
		},
		check_otp() {
			if (this.login_form.ver_code.length == 6) {
				if (this.otp) {
					this.dis = false;
				} else {
					this.dis = true
				}
			} else {
				this.dis = true
			}

		},
		async checkpass() {
			if (this.login_form.pass_word.length >= 6) {
				await this.checkemail()
				if (this.has_err == false) {
					this.dis = false

				} else {
					this.dis = true
				}



			} else {
				this.dis = true
			}
		},
		async checkcode() {
			if (this.login_form.invite_code.length >= 5) {
				await this.checkmob()
				if (this.has_err == false) {
					this.dis = false

				} else {
					this.dis = true
				}



			}
		},
		gotostep(stp) {
			//this.step = stp

		},
		signin() {
			if (this.step < 2) { this.step++; this.dis = true }
			if (this.step == 2) {
				if (this.otp == true) {
					this.post("user/register", this.login_form).then(res => {
						Dialog.alert({
							message: res.info
						}).then(() => {
							if (res.status == 1) {
								this.$router.push({ path: "/login" })
							}
						});
					})

				} else {
					this.checkotp();

				}
				this.btn_text = this.$t('submit')
			}


		},
		async checkmob() {
			this.loading = true;
			let res = await this.get("check_mob", { mob: this.login_form.mob, key: this.token })
			if (res.status == 0) {
				this.has_err = true;
				Dialog.alert({
					message: res.info
				}).then(() => {
					this.loading = false
				});

			} else {
				this.has_err = false;
			}

			this.loading = false

		},
		async checkemail() {
			this.loading = true;
			let res = await this.get("check_email", { email: this.login_form.email, key: this.token })
			if (res.status == 0) {
				this.has_err = true;
				Dialog.alert({

					message: res.info
				}).then(() => {

				});


			} else {
				this.has_err = false;
			}

			this.loading = false
		},
		async checkotp() {
			this.loading = true;

			let res = await this.post("sms_otp", { mob: this.login_form.mob, key: this.token })
			if (res.status == 1) {
				this.otp = true


			}
			this.otp = true
			Dialog.alert({
				message: res.info
			}).then(() => { });

			this.loading = false;

		},


		chengeType(val) {
			this.status = val;
		},

	}
};
</script>

<style lang="scss" scoped>
.close {
	padding: 2rem;


}

.login-sign {
	width: 100vw;
	height: 100vh;
	background: #fff;
	background-size: contain;

	.step {
		div {
			margin-left: 1rem;
			display: inline-block;
			width: 30.33%;
			height: 1rem;
			background: #b31100;
			opacity: 0.1;
			border-radius: 1rem;

			&.ac {
				transition: all 0.5s ease-in;
				-webkit-transition: all 0.5s ease-in;
				/* Safari */
				background: #b31100;
				opacity: 1;
			}
		}
	}

	.logo {
		display: block;
		text-align: center;
		padding-top: 2rem;
		padding-bottom: 2rem;

		img {
			width: 8rem;
			height: 8rem;
		}

		h1 {
			padding-left: 3.5rem;
			text-align: left;
			color: #101729;
			font-size: 3rem;

		}

		h2 {
			color: #97a3b6;
			font-weight: 400;
		}
	}

	.box {
		background: #fff;
		margin: 1rem;

		border-radius: 1rem;
		padding-top: 1rem;

		.tab {
			padding: 0 3rem;

			li {
				display: inline-block;
				list-style: none;
				width: 50%;
				text-align: center;
				padding: 1rem 0;
				font-size: 2rem;
				transition: all 0.3s ease-in-out;
				-webkit-transition: all 0.3s ease-in-out;

				/* Safari */
				&.ac {
					color: #eb4648;
					border-bottom: 3px solid #eb4648;
					font-weight: bold;
					box-shadow: 0px 4px 8px #f2f2f2;
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					/* Safari */
					font-size: 3rem;
				}
			}
		}

		.login {
			.forget {
				padding: 1rem;
				overflow: hidden;

				a {
					float: right;
					color: #b31100;
				}
			}

			.create_account {
				color: #677489;
				text-align: center;
				padding: 2rem;
				overflow: hidden;

				a {
					color: #b31100;
				}
			}

			.ext {
				&.btn {
					overflow: hidden;

					input {
						width: 40vw;
						float: left;
					}

					li {
						float: left;
						list-style: none;
						width: 30vw;

						height: 5rem;
						line-height: 5rem;
						background: #5564f5;
						text-align: center;
						color: #fff;
						border-radius: 3rem;
						margin-top: 2rem;
					}
				}
			}

			transition: all 0.3s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			/* Safari */
			padding: 2rem;

			input {
				border: none;
				background: #f8f9fd;
				border-radius: 0.8rem;
				height: 6rem;
				line-height: 6rem;
				display: block;
				width: 80vw;
				margin-top: 2rem;
				padding-left: 3rem;

				&::placeholder {
					color: #bfbfbf;
				}

				font-size: 2rem;
			}

			.login-btn {
				&.ac {
					background: #f8f5fe;
					color: #643beb;
				}

				background: #653ceb;
				display: block;
				padding: 1.8rem;
				text-align: center;
				border-radius: 1.4rem;
				color: #fff;
				margin-top: 3rem;

				font-size: 2.5rem;

				font-weight: bold;
			}
		}
	}
}
</style>