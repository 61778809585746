<template>
    <div class="AccountHis">
        <nav-bar>Activity</nav-bar>

        <div class="contents">
            <h1 class="ac-titel">{{ activity.title }}</h1>

            <div class="contents" v-html="activity.content"></div>
        </div>
    </div>

</template>

<script>
export default {

    data() {
        return {
            activity: {},


        };
    },

    created() {
        let id = this.$route.query.id
        this.info(id);

    },

    methods: {
        info(id) {
            this.loading = true;


            this.get("activity/info", { id: id }).then(res => {
                this.activity = res.data;



            });
        },





    }
};
</script>

<style lang="scss">
.ac-titel {
    text-align: center;
    background: #fff;
    padding: 1rem;
}

.contents {
    img {
        border: 1px solid #e0dfdf;
        padding: 1rem;
    }

    .video {
        height: 20rem;
        width: 100%;
    }

    .info {
        border-bottom: 1rem solid #e0dfdf;
    }

    span {
        display: block;

        i {
            font-style: normal;
            font-weight: 600;
            color: #000;
            padding-left: 1rem;
        }
    }
}
</style>