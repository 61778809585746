<template>
	<div class="AccountHis">
		<nav-bar>
			Financial records
		</nav-bar>
		<div class="header">
			<li @click="chengeType(0)" :class="{ focus: status == 0 ? true : false }">{{ $t('deposit') }}</li>
			<li @click="chengeType(1)" :class="{ focus: status == 1 ? true : false }">{{ $t('payout') }}</li>
		</div>


		<div class="lists">
			<div v-show="current == 0" ref="recharge">
				<div role="feed" class="van-list">
					<div>
						<dload-more v-model="total" v-bind:loading="floading" @loadmore="getRechargeList">
							<div class="recharge-item" v-for="(item, index) in rechargeList" :key="index">
								<div>
									<div>
										<ul>
											<li class="tp">{{ $t('order_num') }}:{{ item.order_num }}</li>
											<li class="status"
												:class="{ success: item.status == 1 ? true : false, unpaiid: item.status == 0 ? true : false }">
												{{ item.status === 0 ? $t('unpaid') : $t('success') }}</li>
										</ul>
										<ul>
											<li>{{ $t('time') }}:{{ item.created_at }}</li>
											<li class="amount">₹{{ item.amount }}</li>
										</ul>


									</div>
								</div>
							</div>
						</dload-more>
					</div>


				</div>
			</div>

			<div v-show="current == 1" ref="withdrawal" class="Recharge">
				<div role="feed" class="van-list">
					<div>
						<dload-more v-model="total_payout" v-bind:loading="floading" @loadmore="getWithdrawalList">
							<div class="recharge-item" v-for="(item, index) in 	withdrawalList" :key="index">
								<div>
									<div>
										<ul>
											<li class="tp">{{ $t('order_num') }}:{{ item.order_num }}</li>
											<li class="status"
												:class="{ success: item.status == 3 ? true : false, unpaiid: item.status == 0 ? true : false }">
												{{ withdrawstatusFormat(item.status) }}
											</li>
										</ul>
										<ul>
											<li>{{ $t('time') }}:{{ item.created_at }}</li>
											<li class="amount">₹{{ item.amount }}</li>
										</ul>


									</div>
								</div>
							</div>
						</dload-more>

					</div>


				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			total_payout: 0,
			total: 0,
			floading: false,
			current: 0,
			itemList: [{ name: "Recharge Details" }, { name: "Withdrawal" }],

			rechargeList: [],
			withdrawalList: [],
			status: 0,
			loading: false
		};
	},
	created() {
		this.chengeType(0);
	},

	methods: {
		onClickLeft() {
			this.$router.go(-1);
		},
		chengeType(status) {
			this.status = status;
			if (status == 0) {
				this.getRechargeList();
			} else {
				this.getWithdrawalList();
			}
			this.current = status;
		},

		getRechargeList(page) {
			this.floading = true;

			this.get("pay/list", { p: page }).then(res => {
				this.total = res.total;
				this.rechargeList = this.rechargeList.concat(res.data);
				this.floading = false;

			});
		},
		getWithdrawalList(page) {
			this.floading = true
			this.get("payout/list", { p: page }).then(res => {
				this.withdrawalList = this.withdrawalList.concat(res.data);
				this.total_payout = res.total
				this.floading = false
			});
		},

		withdrawstatusFormat(status) {
			switch (status) {
				default:
					return this.$t('pending');
				case 0:
				case 1:
					return this.$t('pending');
				case 2:
					return this.$t('refund');
				case 4:
					return this.$t('pending');
				case 3:
					return this.$t('success');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.lists {
	background: #fff;
	height: 100vh;
	overflow-y: scroll;
}

.loading {
	display: block;
	position: absolute;
	background: #fff;
	width: 100vw;
	height: 80vh;
	text-align: center;
	line-height: 80vh;
	z-index: 10;
}

.header {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;



	font-weight: bold;
	font-size: 1.8rem;
	background: #fff;

	border-bottom: 1px solid #e9e8e7;
	display: flex;
	justify-content: space-around;
	padding: 1rem 0rem;

	li {

		padding: 1rem 2rem;
		text-align: center;


		list-style: none;

		color: #bab9b9;
		background: #fff;
		font-size: 1.7rem;
		width: 50%;



		&.focus {
			border-bottom: 1px solid #df565b;

			color: #df565b;


		}
	}
}

p.success {
	color: #228b22 !important;
}

.recharge-item {
	padding: 1.5rem 1rem;

	border-bottom: 1px solid #f5f7f8;

	div {
		padding: 0;


		display: block;

		ul {
			display: block;
			padding: 0.5rem 0;

			li {
				&.amount {
					float: right;
					padding-right: 2rem;
					color: #111729;
					font-size: 2rem;
				}

				&.status {
					text-align: right;
					display: block;
					padding-right: 2rem;
					float: right;

					&.success {
						color: #5bbf63;

					}

					&.unpaiid {
						color: #f20101;
					}
				}


				display: inline-block;

				&:first-child {
					color: #cbcccd;

					&.tp {
						color: #525155;
					}
				}
			}









		}
	}
}

.nomare {
	color: #969799;
	font-size: 14px;
	line-height: 50px;
	text-align: center;
}
</style>