import VueRouter from 'vue-router'
import Home from './pages/home.vue'
import Trde from './pages/trde.vue'
import Bank from './pages/funds/bank.vue'
import Profile from './pages/profile.vue'
import TrdeView from './pages/trde/tradeview.vue'
import Deposit from './pages/funds/deposit.vue'
import Recods from './pages/trde/recods.vue'
import OrderDetail from './pages/trde/detail.vue'
import Hist from './pages/funds/hist.vue'
import SafeList from './pages/safe/list.vue'
import Verif from './pages/safe/verif.vue'
import Setting from './pages/setting/seting.vue'
import Upload from './pages/setting/upload.vue'
import Loginpassword from './pages/safe/pass.vue'
import Login from './pages/login.vue'
import Pro from './pages/pro.vue'
import ProDetail from './pages/pro_detail.vue'
import Download from './pages/dowload.vue'
import Reg from './pages/reg.vue'
import ForgotPass from './pages/findpass.vue'
import Webview from './pages/webview.vue'
import Page from './pages/page.vue'
import Kyc from './pages/user/kyc.vue'
import Share from './pages/user/share.vue'
import Service from './pages/user/service.vue'
import Reward from './pages/user/reward.vue'
import Notif from './pages/user/notify.vue'
import Announcement from './pages/user/announcement.vue'
import Settlement from './pages/user/settlement.vue'
import AddBank from './pages/user/addbank.vue'
import Payout from './pages/funds/payout.vue'
import UserInfo from './pages/user/userinfo.vue'
import PowerBuy from './pages/buypower.vue'
import Direct from './pages/direct.vue'
import Activities from './pages/activities.vue'
import Center from './pages/user/center.vue'
import Salary from './pages/funds/sarly_log.vue'
import UpdateBank from './pages/user/update_bank.vue'
import Chart from './pages/chat.vue'
import Channel from './pages/channel.vue'
import ChatMian from './pages/chat/chat_list.vue'
import ChatBox from './pages/chat/chat_box.vue'
import HelpList from './pages/help/list.vue'
import HelpInfo from './pages/help/info.vue'
import BrokerInfo from './pages/broker/info.vue'
import ActivityInfo from './pages/activity/info.vue'
import ActivityList from './pages/activity/list.vue'
import NewsList from './pages/news/list.vue'
import NewsInfo from './pages/news/info.vue'
import Find from './pages/find.vue'
const routers = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/pro',
    component: Pro,

  },
  {
    path: '/find',
    component: Find,

  },
  {
    path: '/news_info',
    component: NewsInfo,

  },
  {
    path: '/news_list',
    component: NewsList,

  },
  {
    path: '/activites',
    component: ActivityList,

  },
  {
    path: '/broker_info',
    component: BrokerInfo,

  },
  {
    path: '/activity_info',
    component: ActivityInfo,

  },
  {
    path: '/pro_detail',
    component: ProDetail
  },
  {
    path: '/help_info',
    component: HelpInfo,

  },
  {
    path: '/help',
    component: HelpList
  },

  {
    path: '/login',
    component: Login
  },
  {
    path: '/chat',
    component: ChatMian
  },
  {
    path: '/chat_box',
    component: ChatBox
  },
  {
    path: '/share',
    component: Share
  },
  {
    path: '/detail',
    name: "detail",
    component: OrderDetail
  },
  {
    path: '/update_bank',
    component: UpdateBank
  },
  {
    path: '/announcement',
    component: Announcement
  },
  {
    path: '/service',
    component: Service
  },
  {
    path: '/notify',
    component: Notif
  },
  {
    path: '/reward',
    component: Reward
  },
  {
    path: '/settlement',
    component: Settlement
  },
  {
    path: '/channel',
    component: Channel,
    meta: {
      keepAlive: true
    },
  },

  {
    path: '/kyc',
    component: Kyc
  },
  {
    path: '/signin',
    component: Reg
  },
  {
    path: '/forgotpass',
    component: ForgotPass
  },
  {
    path: '/bank_transfer',
    component: Bank
  },
  {
    path: '/download',
    component: Download
  },
  {
    path: '/activities',
    component: Activities
  },
  {
    path: '/direct',
    component: Direct,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/buy',
    component: PowerBuy
  },
  {
    path: '/reg',
    component: Reg
  },
  {
    path: '/userinfo',
    component: UserInfo
  },
  {
    path: '/home',
    component: Home,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/trde',
    component: Trde,
    meta: {

      keepAlive: true
    },
  },


  {
    path: '/profile',
    component: Profile,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/kline',
    component: TrdeView,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/deposit',
    component: Deposit
  },
  {
    path: '/verif',
    component: Verif
  },
  {
    path: '/webview',
    component: Webview
  },
  {
    path: '/payout',
    component: Payout
  },

  {
    path: '/recods',
    component: Recods,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/hist',
    component: Hist
  },
  {
    path: '/upload',
    component: Upload
  },
  {
    path: '/safe',
    component: SafeList,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/center',
    component: Center,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/salary',
    component: Salary,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/settings',
    component: Setting,
    meta: {

      keepAlive: true
    },
  },
  {
    path: '/changeloginpass',
    component: Loginpassword
  },
  {
    path: '/bind_card',
    component: AddBank
  },
  {
    path: '/page',
    component: Page
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: '/app/',
  routes: routers
})
export default router