<template>
    <div class="AccountHis">
        <nav-bar>{{ help.title }}</nav-bar>
        <div class="contents">
            <div v-html="help.contents"></div>
        </div>



    </div>

</template>

<script>
export default {

    data() {
        return {
            help: {}

        };
    },
    created() {
        let id = this.$route.query.id
        this.info(id);

    },

    methods: {
        info(id) {
            this.loading = true;
            this.select_index = id;

            this.get("help/info", { id: id }).then(res => {
                this.help = res.data;
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            });
        },





    }
};
</script>

<style lang="scss">
.contents {
    min-height: 100vh;
    background: #fff;
    padding: 2rem;

    img {
        text-align: center;
        max-width: 90vw;
        max-height: 30vh;

    }

    font-size: 2rem;
    line-height: 160%;
}
</style>