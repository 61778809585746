<template>
    <div class="helps">
        <nav-bar>Activites</nav-bar>


        <div class="lists">

            <div class="item" v-for="(item, index) in activityList" :key="index">
                <router-link :to="{ path: '/activity_info', query: { id: item.id } }">
                    <img :src="host + '/' + item.img" />
                    <h1>{{ item.title }}</h1>
                </router-link>
            </div>

        </div>


    </div>

</template>

<script>
export default {

    data() {
        return {


            activityList: [],




            status: 0,
            floading: false
        };
    },
    created() {
        this.getHlepList();


    },

    methods: {
        go() {
            this.$router.push({ path: "/info" })
        },


        getHlepList() {


            this.floading = true;
            this.get("activity/list").then(res => {

                this.activityList = res.data



            });
        },




    }
};
</script>

<style lang="scss" scoped>
.helps {
    height: 100vh;
    overflow-x: hidden;
}


.lists {

    background: #fff;
    height: 90vh;
    overflow-y: scroll;
    padding-top: 1rem;

    .item {
        display: block;
        padding: 0.2rem 2rem;

        img {
            max-width: 90vw;
        }

        h1 {

            color: #555454;
            font-weight: 400;
        }

        border-bottom: 1px solid #f5f5f5;
    }
}
</style>