
export default {
    initHomePage() {

        // this.get_focus_img()

        this.get_activity_list();
        //  this.get_broker_list();
        this.get_news_list();
        this.get_info();
        this.getMsg();



    },
    marketData(data) {
        let codes = data.ch.split(".");
        if (codes.length != 3) {
            return;
        }
        let code = codes[1]

        if (code == "btcusdt" || code == "ethusdt" || code == "ltcusdt") {
            let name = ""
            if (code == "btcusdt") {
                name = "GBY INDEX1"
            } else if (code == "ethusdt") {
                name = "GBY INDEX2"
            } else {
                name = "GBY INDEX3"
            }

            this.$store.dispatch("updateMarketDataCommit", { code: code, price: data.tick.close, name: name })
        }



    },
    get_info() {
        this.get("user/info").then(res => {
            this.info = res.data;
            this.creat_acc();
        })
    },
    getMsg() {
        this.get("channel/list", { tp: 2 }).then((res) => {
            if (res.data.length == 0) {
                return
            }
            this.msgs = res.data[0].msg;
            this.showPop2 = true
        });
    },

    export_timefn() {


        function addZero(i) {
            return i < 10 ? "0" + i : i;
        }
        let timer;
        const startTime = new Date(this.start_time);
        let dates = new Date(this.start_time);
        console.log(startTime.getDate(), startTime.getMonth())

        const endTime = new Date(dates.setDate(dates.getDate() + this.days))
        // 注：这里是直接使用用户本地系统时间做演示
        const nowTime = new Date();
        // 当前时间距离活动开始时间的差值
        const beginDiff = parseInt((startTime.getTime() - nowTime.getTime()) / 1000);
        //当前时间距离活动结束时间的差值
        const endDiff = parseInt((endTime.getTime() - nowTime.getTime()) / 1000);
        // 剩余时间
        const leftTime = beginDiff > 0 ? beginDiff : endDiff;
        let day = parseInt(leftTime / (24 * 60 * 60))
        let hour = parseInt(leftTime / (60 * 60) % 24);
        let minute = parseInt(leftTime / 60 % 60);
        let second = parseInt(leftTime % 60);
        day = addZero(day);
        hour = addZero(hour);
        minute = addZero(minute);
        second = addZero(second);

        if (beginDiff > 0) {
            this.export_time = `The Activit has not started`;
            timer = setTimeout(this.export_timefn, 1000);
        } else if (leftTime <= 0) {
            this.export_time = 'Activity has ended!';
            clearTimeout(timer);
        } else {
            this.export_time = ` ${day}DAYS ${hour}: ${minute}:${second}`;
            timer = setTimeout(this.export_timefn, 1000);
        }
    },
    get_focus_img() {
        this.get("focus/list").then(res => {
            this.images = res.data;
        })
    }
    ,
    get_activity_list() {
        this.get("activity/list").then(res => {
            let times = res.data[0].created_at.split(" ")
            console.log(times)

            this.start_time = res.data[0].created_at
            this.days = res.data[0].export_time

            this.$store.dispatch("updateAxtivitiysCommit", res.data)
            this.export_timefn();
        })
    },
    get_broker_list() {
        this.get("broker/list").then(res => {
            this.brokers = res.data;
        })
    }
    ,
    gohelp(id) {
        this.$router.push({ path: '/help_info', query: { id: id } })
    },
    get_news_list() {
        this.get("news/list").then(res => {
            this.news = res.data;
        })
    },
    creat_acc() {

        this.post_chat("/create", { plat: this.plat, nick_name: this.info.name + '-' + this.info.code, vip: 0 }).the(res => {
            alert(11)
        })
    },

}