<template>
	<div class="btn" @click="handleClick" v-bind:class="{ btnload: loading, dis: dis }">
		<i>
			<slot></slot>
		</i>
	</div>
</template>

<script>
export default {
	name: "def-btn",

	props: { loading: Boolean, dis: Boolean },
	methods: {
		handleClick(data) {
			if (this.loading) {
				return false
			}


			if (this.dis == true) {
				console.log("sd")
				return false
			}
			this.$emit("click", data);
		}
	}
};
</script>

<style lang="scss" scoped>
.btn {
	i {
		font-size: 2rem !important;
		font-style: normal;
		font-weight: 500;
		color: #fff;
	}

	position: relative;

	div {
		display: inline-block;
	}

	text-align: center;
	height: 6rem;
	line-height: 6rem;
	border: none;
	background: #df565b;
	color: #fff;
	text-align: center;
	margin: 2rem auto;

	border-radius: 3rem;

	&.dis {
		background: #d2cdd6;
	}
}

.btnload {
	background: #653ceb;
	opacity: 0.8;
	cursor: default;
	pointer-events: none;
	animation: op 4s ease infinite;

}

.btnload:before {
	content: "";
	display: inline-block;
	width: 2rem;
	height: 2rem;
	margin-right: 0.5rem;
	color: #fff;
	border: 0.4rem solid #fff;
	border-radius: 50%;
	vertical-align: -10%;
	clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0% 30%);
	animation: rotate 0.5s linear infinite;
}

@keyframes op {
	from {
		width: 0%;
		border-radius: 50rem;
		//opacity: 0.8;
		//background: #faf9fb;

	}

	to {
		border-radius: 1rem;

		width: 100%;
		//opacity: 1;
		//background: #653ceb;

	}
}

@keyframes rotate {
	from {
		transform: rotatez(0deg);
	}

	to {
		transform: rotatez(360deg);
	}
}
</style>