<template>
    <div class="AccountHis">
        <nav-bar>News</nav-bar>
        <div class="contents">
            <h1> {{ help.title }}</h1>
            <div v-html="help.content"></div>
        </div>



    </div>

</template>

<script>
export default {

    data() {
        return {
            help: {}

        };
    },
    created() {
        let id = this.$route.query.id
        this.info(id);

    },

    methods: {
        info(id) {
            this.loading = true;
            this.select_index = id;

            this.get("news/info", { id: id }).then(res => {
                this.help = res.data;

            });
        },





    }
};
</script>

<style lang="scss">
.contents {
    h1 {
        color: #000;
        font-weight: 700;
    }

    min-height: 100vh;
    background: #fff;
    padding: 2rem;

    img {
        text-align: center;
        max-width: 90vw;
        max-height: 30vh;

    }

    font-size: 2rem;
    line-height: 160%;
}
</style>