<template>
	<div>
		<nav-bar>{{ $t('deposit') }}</nav-bar>
		<div class="Recharge">

			<ul class="recharge-amount">
				<p class="title">{{ $t('recommand_amount') }}</p>
				<li :class="{ focus: active == index ? true : false }" class v-for="(item, index) in amountList"
					:key="index" @click="changeAmount(index)">₹{{ item }}</li>
			</ul>

			<base-input v-model="amount" class="input" maxlength="10" label="Amount:"
				:roule="{ reg: '[0-9]{2}', msg: 'Invild Amount' }" placeholder="Amount"></base-input>
			<div class="pay-btn">
				<base-btn :loading="loading" @click="channel_status = true">{{ $t('continue') }}</base-btn>
			</div>
		</div>
		<van-popup class="td" :overlay="true" v-model="channel_status" position="bottom"
			:style="{ height: '50%', width: '100%' }">
			<div class="channel_title">Please select a recharge channel</div>

			<div class="channel_list">
				<li :class="{ ac: item.id == channel_id }" v-for="(item, index) in channel_list" :key="index"
					@click="channel_id = item.id">
					{{ item.title }}


				</li>

			</div>
			<div class="btns"><base-btn :loading="loading" @click="pay">{{ $t('continue') }}</base-btn></div>


		</van-popup>


	</div>
</template>

<script>
import { Dialog } from "vant";
export default {
	name: "Recharge",
	data() {
		return {
			channel_status: false,
			active: 1,
			radio: "",
			form: {
				amountVal: ""
			},
			loading: false,
			amount: "500",
			amountList: [],
			balance: 0,
			tp: 0,
			info: "",
			channel_list: [],
			channel_id: ""
		};
	},
	created() {
		this.getInfo();
		this.paylimit();
		this.getchannel();
	},

	mounted() {
		this.getInfo();
		this.paylimit();
		this.getchannel();
	},
	methods: {
		getchannel() {
			this.get("pay/channel").then(res => {
				this.channel_list = res.data
				this.channel_id = res.data[0].id

			})
		},
		paylimit() {
			this.get("pay/limit").then(res => {
				this.amount = res.data[1]
				this.amountList = res.data
			})
		},
		paymennt(val) {
			this.tp = val;
		},
		onClickRight() {
			this.$router.push("./hist");
		},
		getInfo() {
			this.get("user/info").then(res => {
				this.balance = res.data.balance;
				this.info = res.data;
			});
		},
		changeAmount(index) {
			//console.log(this.amountList[index]);
			this.amount = this.amountList[index];
			this.active = index;
		},

		IsAppleStore() {
			let u = navigator.userAgent,
				app = navigator.appVersion;
			let ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			let iPad = u.indexOf("iPad") > -1;
			let iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1;
			if (ios || iPad || iPhone) {
				return true;
			} else {
				return false;
			}
		},
		pay() {
			this.loading = true;

			this.post("pay/create", { amount: this.amount, tp: this.tp, payin_channel_id: this.channel_id }).then(
				res => {
					this.loading = false;
					//console.log(res);

					if (res.status == 0) {
						Dialog.alert({
							message: res.info
						}).then(() => {
							this.loading = false;
						});
					} else {
						if (res.data.tp == 1) {
							this.$router.push({
								path: "/bank_transfer",
								query: {
									code: this.info.code,
									orderid: res.data.url,
									amount: this.amount
								}
							});
							return;
						}
						if (this.IsAppleStore) {
							window.location.href = res.data.url;
						} else {
							window.open(res.data.url);
						}
					}
				}
			);
		},
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.pay-btn {
	padding: 1rem;
}

.channel_title {
	padding: 1rem;
	border-bottom: 1px solid #e9e8e7;
	color: #171617;
	font-size: 2rem;
}

.channel_list {
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
	justify-content: start;

	li {
		margin: 0.5rem;
		width: 40%;
		text-align: center;


		list-style: none;
		padding: 1.5rem 1rem;
		border: 1px solid #f84103;
		border-radius: 2rem;
		color: #f84103;

		&.ac {
			background: #f84103;
			color: #fff;
		}

	}
}

.btns {
	padding: 2rem;
}

.notice {
	padding: 10px;
}

.Recharge {
	padding: 0 2rem;
	height: 100%;
	background: #fff;
	overflow: hidden;
	padding-top: 5rem;




	.rechargeinput {
		background: #fff;
		padding: 2rem;
	}

	.recharge-amount {
		.title {
			display: block;
			border-bottom: 1px dashed #e3e8ef;
			width: 100%;
			padding: 0.2rem 0;
			font-size: 2rem;
			font-weight: bold;
			color: #2b2e39;
		}

		border-radius: 2px;
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.2rem;
		justify-content: space-between;
		padding-top: 2.4rem;
		background: #fff;
		padding: 1rem;

		li {
			text-align: left;
			padding-left: 2rem;
			position: relative;
			transform: scale(1, 1);
			transition: all 0.3s ease-in-out;

			&.focus {
				transform: scale(1.01, 1.01);
				transition: all 0.5s ease-in-out;
				border: 1px solid #f84103 !important;
				color: #fff;
				font-weight: bold;

				&:before {
					content: " ";
					background-size: contain !important;
					display: block;

					width: 2.25rem;
					height: 2.15rem;
					position: absolute;
					right: 0;
					bottom: 0;
				}

				background:#f84103;
				border: 1px solid #e9e8e7;
			}

			width: 40%;
			height: 3.9rem;
			line-height: 3.9rem;

			border: 1px solid #e9e8e7;
			border-radius: 1rem;
			overflow: hidden;
			margin-bottom: 5px;
			color: #000;
			font-size: 2rem;
			font-weight: 400;
		}
	}
}
</style>