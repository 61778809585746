export default {
    options: {
        host: "https://lfgfinances.com",
        api_url: "https://lfgfinances.com/api/",//网站
        chat_host: "https://lof.lofchat.com",//聊天网址
        chat_ws_host: "wss://lof.lofchat.com/api/ws",//聊天ws地址
        chat_dname: "https://lof.lofchat.com/",//聊天网址
        market_data_api: "wss://bitdeepquants.net/api/ws",
        plat_name: "Luminary Finance",//平台名字
        plat: "LFGUSER"


    }

}