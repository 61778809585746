<template>
	<div class="main">
		<div class="header">

			<div class="box">
				<div class="info">
					<img src="../assets/imgs/face@2x.png" />
					<div class="account">
						<li>{{ info.name }}</li>
						<li>UID:{{ info.code }}</li>
					</div>
					<div class="account_info">

						<li class="lv">VIP0</li>


					</div>


				</div>



			</div>
			<div class="flat-box">
				<div class="warp">

					<div class="detail">

						<div class="item">
							<li>{{ info.balance }}</li>
							<li>Binary</li>
						</div>

						<div class="item">
							<li>{{ info.salary }}</li>
							<li>Salary</li>
						</div>





					</div>


				</div>
			</div>

		</div>
		<div class="recharge">

			<div @click="depoist">Deposit</div>

			<div @click="withdraw">withdraw</div>
		</div>

		<div class="actions">

			<router-link :to="{ path: '/service', query: { code: code } }">
				<div class="item">

					WhatsApp Service
					<i>Account Settlement</i>
				</div>
			</router-link>

			<router-link to="/share">
				<div class="item">

					Invite friends
					<i>Account Settlement</i>
				</div>
			</router-link>

			<router-link to="/settlement">
				<div class="item">

					{{ $t('fundlog') }}
					<i>Account Settlement</i>
				</div>
			</router-link>
			<router-link to="/salary">
				<div class="item">

					{{ $t('sarlry_log') }}
					<i>Payroll Records</i>
				</div>
			</router-link>
			<router-link to="/hist">
				<div class="item">

					{{ $t('fund_log') }}
					<i>Account Settlement</i>
				</div>
			</router-link>


			<router-link to="/recods">
				<div class="item">

					{{ $t('hist') }}
					<i>Transaction History</i>
				</div>
			</router-link>

			<router-link to="/safe">
				<div class="item">

					{{ $t('acc_safe') }}

				</div>
			</router-link>




			<div @click="logout" class="signout">{{ $t('sigin_out') }}</div>




		</div>
		<Tabbar :now="'4'" />

	</div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
import Clipboard from "clipboard";
import TopHeader from "./header.vue";
import { Dialog } from "vant";
import AnimatedNumber from "animated-number-vue";
export default {
	name: "Profile",
	components: {
		Tabbar,
		AnimatedNumber,
		TopHeader
	},
	created() {
		this.getInfo();
	},
	data() {
		return {
			code: "",
			wallet: {},
			info: [],
			lang_list: [{ title: "English", code: "en" }, { title: "Hindi", code: "hd" }],

			total: 0.0,





		};
	},
	activated() {
		this.getInfo();

		let _this = this;
	},
	created() {


	},
	computed: {
		balance: {
			get() {
				let total = this.wallet.binary + this.wallet.salary + this.wallet.reward + this.wallet.freeze
				return total;
			}
		}
	},

	methods: {
		withdraw() {
			this.$router.push({ path: "/payout" })
		},
		depoist() {
			this.$router.push({ path: "/deposit" })
		},

		logout() {
			localStorage.clear();
			this.$router.push("/login");
		},



		getInfo() {
			this.get("user/info").then(res => {
				this.code = res.data.code;
				this.info = res.data;


			});
		},
		formatToPrice(value) {
			if (this.eyes === false) {
				return "₹******";
			}
			return `₹${Number(value).toFixed(2)}`;
		},


	}
};
</script>

<style lang="scss" scoped>
.lists {
	padding: 2rem;

	li {
		display: block;
		padding: 1.5rem 2rem;
		text-align: center;
		list-style: none;
		border-bottom: 1px solid #e9e8e7;

	}
}

.main {
	padding-bottom: 10rem;
	padding-top: 20rem;
	background: #fff;

	.signout {
		text-align: center;
		margin: 1rem;
		padding: 2rem;
		background: #f1f1f2;
		color: #6c6c6d;
		border-radius: 1rem;
	}

	.recharge {
		background: #f3f3f3;
		padding-top: 2rem;
		display: flex;
		justify-content: space-around;




		div {

			width: 40%;
			margin: 1rem;
			padding: 1rem;
			color: #fff;
			border-radius: 2rem;
			text-align: center;

			&:first-child {
				background: #df5b5f;
			}

			&:last-child {
				background: #979798;

			}
		}

	}


	.header {
		background: #fff;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		display: block;

		background-size: cover;

		padding: 0 0rem;

		h1 {
			font-weight: normal;
			color: #fff;
			text-align: center;

			margin: 0;
			padding-top: 1rem;
			font-size: 2.5rem;
		}

		.flat-box {

			height: 8rem;
			position: relative;

			.warp {

				top: -3rem;
				border-radius: 3rem 3rem 3rem 3rem;
				background: #fff;
				position: absolute;
				z-index: 10000;
				left: 0;
				width: 100vw;
				//border-bottom: 2px solid #df5b5f;



				.detail {
					display: flex;
					justify-content: space-around;

					background-size: 2rem;
					padding-top: 4rem;
					padding-left: 2rem;







					li {
						padding-left: 1rem;
						padding: 0.5rem 0;
						text-align: left;


						justify-content: start;
						list-style: none;
						color: #0d122f;


						&:first-child {
							font-weight: 600;
							font-size: 2rem;
							color: #787676;



						}

						&:last-child {
							color: #8d959f;

						}
					}
				}
			}

		}

		.box {

			background: #df5b5f;



			.account_info {

				padding: 3rem 2rem;
				display: flex;
				flex-wrap: nowrap;

				justify-content: stretch;

				li {
					vertical-align: middle;
					color: #fff;
					list-style: none;
					padding: 1rem;

					&.lv {
						//box-shadow: 3px 3px 5px rgb(192, 188, 188);
						color: #fcfcfd;
						border: 1px solid #fefeff;
						padding: 0.5rem 1rem;
						border-radius: 1rem;
					}
				}
			}





			.info {

				display: flex;
				flex-wrap: nowrap;

				justify-content: stretch;


				padding: 2.6rem 0rem;
				padding-bottom: 1rem;

				img {
					margin-left: 2rem;


					width: 6.25rem;
					height: 6.25rem;

				}

				.account {


					list-style: none;

					margin-left: 2rem;

					li {
						text-align: left;
						padding: 0.5rem 0;
						display: block;
						color: #fff;

						&:last-child {
							color: #fcfcfc;
							opacity: 0.9;
						}
					}

					li:first-child {

						font-weight: bold;
					}

				}


			}

		}
	}

	.actions {

		margin: 0 0rem;
		border-radius: 1rem;
		margin: 1rem;
		//border: 1px solid #e3e8ef;
		background: #fff;

		.item {

			display: block;

			color: #56585d;
			font-size: 2rem;
			//border: 1px solid #e3e8ef;
			margin: 0 1rem;
			//border-radius: 1rem;
			padding: 1.5rem 2rem;

			i {

				display: none;
				font-style: normal;
				color: #677389;

				padding: 0.2rem 0;

			}

			background:#fff url("../assets/bit/icon/chevron-right.png") no-repeat 37rem center;
			background-size: 2rem;


		}
	}
}
</style>
