<template>
    <div class="load-more">
        <slot></slot>
        <div class="nodata" v-if="total_data == 0">--No data--</div>
        <div v-if="page_index < page_size && page_size > 1" @click="more_click" class="more-loading">
            <van-loading v-if="locloading == true" type="spinner" color="#fb0202" />
            <div v-if="locloading == false">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "load-more",
    props: {
        loading: Boolean,
        value: {
            type: Number,
            default: () => {
                return 1
            }
        },
    },
    watch: {
        //监听子组件数据变化
        page_index: function (newVal) {

            //更新父组件数据
            this.$emit('change', newVal);
            this.$emit('loadmore', this.page_index);


        },
        locloading: {
            handler(newV, oldV) {

                if (newV == true) {
                    this.text = "Loading..."
                } else {
                    this.text = "More>>"
                }
                console.log("变化2", newV)
                this.$emit('loading', newV);
                this.locloading = newV;


            },
            deep: true,
            immediate: true,

        },
        loading: {
            handler(newV, oldV) {

                this.locloading = newV;

                if (newV == false) {
                    this.text = "More>>"
                }



            },
            deep: true,
            immediate: true,
        }
        ,
        value: {
            handler(newV, oldV) {
                this.loacvalue = newV;
                this.page_size = Math.ceil(newV / 10)
                this.total_data = newV


            },
            deep: true,
            immediate: true,


        },

    },
    data() {
        return {
            total_data: 0,
            locloading: this.loading,
            loacvalue: this.value,
            page_size: 0,
            page_index: 1,
            text: "More>>"
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },


    methods: {
        more_click() {
            if (this.page_index < this.page_size) {

                this.page_index++
                this.locloading = true;
            }
        },
        handleScroll(e) {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {

                if (this.page_index < this.page_size) {
                    // this.page_index++
                }


            }
        },


    }
};
</script>

<style lang="scss" scoped>
.more-loading {
    margin-bottom: 5rem;
    display: block;
    clear: both;
    padding: 1rem 0;
    text-align: center;

    border: 1px solid #f8f7f7;
    margin: 0.5rem;
    border-radius: 3rem;

    div {
        color: #fb0202;
    }

}

.nodata {
    color: #a1a1a1;
    padding: 1rem;
    padding-top: 10rem;
    text-align: center;
    background: #fff url("../assets/new/empty.png") no-repeat center center;
    background-size: 5rem;
}
</style>