<template>
    <div class="AccountHis">
        <nav-bar>{{ $t('fundlog') }}</nav-bar>


        <div v-show="loading" class="loading">
            <van-loading type="spinner" color="#1989fa" />
        </div>
        <div class="lists">
            <div ref="recharge">
                <div role="feed" class="van-list">
                    <dload-more v-model="total" v-bind:loading="floading" @loadmore="getRechargeList">

                        <div class="recharge-item" v-for="(item, index) in rechargeList" :key="index">


                            <ul>
                                <li class="tp" :class="{ cr: item.amount > 0, dr: item.amount < 0 }">
                                    {{
            item.amount > 0 ? "Cr" : "Dr"

        }} <i>{{ item.transaction_type.title
                                        }}</i></li>
                                <li class="amount">{{ formatToPrice(item.amount) }}</li>

                            </ul>


                            <ul>

                                <li>

                                    {{ $t('balance') }}:
                                    {{ formatToPrice(item.balance)
                                    }}
                                </li>


                                <li class="time">{{ $t('time') }}:{{ item.created_at }}</li>

                            </ul>





                        </div>
                    </dload-more>


                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: "AccountHis",
    data() {
        return {
            floading: false,
            total: 0,
            current: 0,
            itemList: [{ name: "Recharge Details" }, { name: "Withdrawal" }],

            rechargeList: [],
            withdrawalList: [],
            status: 0,
            loading: false
        };
    },
    created() {
        this.getRechargeList(0);
    },

    methods: {
        formatToPrice(value) {

            return `₹${Number(value).toFixed(2)}`;
        },
        onClickLeft() {
            this.$router.go(-1);
        },


        getRechargeList(page) {
            this.floading = true;
            let params = {};
            this.get("user/settlement", { p: page }).then(res => {
                this.rechargeList = this.rechargeList.concat(res.data);
                this.total = 0;
                this.floading = false;

            });
        },



    }
};
</script>

<style lang="scss" scoped>
.lists {
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
}

.loading {
    display: block;
    position: absolute;
    background: #fff;
    width: 100vw;
    height: 80vh;
    text-align: center;
    line-height: 80vh;
    z-index: 10;
}

p.success {
    color: #228b22 !important;
}

.recharge-item {
    padding: 1.5rem 1rem;

    border-bottom: 1px solid #f5f7f8;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;



    ul {
        display: block;
        padding: 0.5rem 1rem;

        &:last-child {
            li {
                color: #949393;
                padding: 0.3rem 0;
                text-align: right;

                &.time {
                    padding: 1rem 0;
                    color: #d6d3d3;
                }


            }
        }

        li {
            i {

                text-align: left;
                font-style: normal;
                font-size: 0.8rem;
                color: #bcb9b9;
            }

            vertical-align: text-bottom;
            padding: 0.3rem 0;

            &.amount {
                float: right;
                padding-right: 1.8rem;
                color: #111729;
                font-size: 2rem;
            }

            &:first-child {


                &.cr {
                    color: #5bbf63;
                }

                &.dr {
                    color: #d64080;
                }
            }
        }










    }
}

.nomare {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}
</style>