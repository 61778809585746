export default {
	state() {
		return {
			activits: [],
			market_data: new Map(),

		};
	},
	mutations: {
		updateAxtivitiys(state, payload = []) {

			state.activits = payload;

		},
		updateMarketData(state, payload = {}) {
			let mp = new Map();

			if (state.market_data.size > 0) {

				state.market_data.forEach((val, key) => {

					mp.set(key, val)
				});
			}

			mp.set(payload.code, payload)
			state.market_data = mp;

		},

	},
	actions: {
		updateAxtivitiysCommit(context, payload) {
			context.commit('updateAxtivitiys', payload);
		},
		updateMarketDataCommit(context, payload) {
			context.commit('updateMarketData', payload);
		},

	},
};
