<template>
    <div class="AccountHis">
        <nav-bar>Agents Infomation</nav-bar>
        <div class="contents" v-if="broker">
            <div class=" info">
                <img :src="host + '/' + broker.img" />
                <span>Name:<i>{{ member.name }}</i></span>
                <span>Code:<i>{{ member.code }}</i></span>
                <span>Level:<i>{{ broker.level }}</i></span>
            </div>
            <div class="contents" v-html="broker.introduce"></div>
        </div>
    </div>

</template>

<script>
export default {

    data() {
        return {
            broker: {},
            member: {}

        };
    },

    created() {
        let id = this.$route.query.id
        this.info(id);

    },

    methods: {
        info(id) {
            this.loading = true;


            this.get("broker/info", { id: id }).then(res => {
                this.broker = res.data;
                this.member = res.data.member


            });
        },





    }
};
</script>

<style lang="scss">
.contents {
    img {
        border: 1px solid #e0dfdf;
        padding: 1rem;
    }

    .video {
        height: 20rem;
        width: 100%;
    }

    .info {
        border-bottom: 1rem solid #e0dfdf;
    }

    span {
        display: block;

        i {
            font-style: normal;
            font-weight: 600;
            color: #000;
            padding-left: 1rem;
        }
    }
}
</style>