import config from '../../../config'
var Socket = { ws }

const wsuri = config.options.market_data_api;
const ws = {
    connction: {},
    connected: false
}

const subfun = new Map();
var onopen = function () {
    ws.connected = true
    console.log("connected!")
}
var onclose = function () {
    ws.connected = false;
    ws.connction = new WebSocket(wsuri);
    var timer = setInterval(() => {
        if (ws.connected == true) {
            clearInterval(timer)
        }
        ws.conn();
    }, 100);
}

var onmessage = function (data) {

    let da = JSON.parse(data.data);
    if (da.ping != null) {
        ws.connction.send('{"pong":' + da.ping + '}')

    } else {
        subfun.forEach((val) => {
            val(da)
        });



    }

}

ws.Sub = function (callback, name) {
    subfun.set(name, callback)
    // subfun[name] = callback
}

ws.UnSub = function (name) {

    delete subfun[name];
}
ws.sendData = function (data) {

    let str = JSON.stringify(data)
    if (this.connected == false) {
        ws.send(str)
    } else {
        let interval = setTimeout(() => {
            if (this.connected == true) {

                ws.connction.send(str)
                clearInterval(interval);
            }

        }, 10);
    }


}
ws.conn = function () {


    if (this.connected == false) {
        ws.connction = new WebSocket(wsuri);
        ws.connction.onopen = onopen
        ws.connction.onclose = onclose
        ws.connction.onmessage = onmessage
        ws.connected = true;

    }


}
export default ws;
