<template>
    <div>
        <nav-bar>Customer Service</nav-bar>


        <div class="safe">
            <div v-if="verifed" class="success"> <i>Hi:</i><i>May I help you?</i></div>
            <div class="contact">Contact us via under methods</div>
            <div class="service">

                <div @click="was"><img src="../../assets/bit/wa.png"></div>


            </div>




            <tip color="#1989fa">
                Due to the heavy workload of customer service, if you cannot reply in time, please understand and thank
                you
                for your support</tip>
            <van-popup class="td" :overlay="true" v-model="dialogVisible" position="bottom"
                :style="{ height: '80%', width: '100%' }">
                <div class="online-chat">
                    <div class="content">
                        We recommend that you contact online customer service through the online customer service system
                        to
                        get a faster response
                    </div>


                    <base-btn @click="onelineservice">Touch Online Customers Service</base-btn>
                    <div class="btnwa" @click="dialogVisible = false">Contact Via Whatsapp</div>
                </div>
            </van-popup>


        </div>
    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            loading: false,
            wa: "",
            channel: "",
            code: "",
            herf: "",
            dialogVisible: false,


        };
    },

    created() {
        this.code = this.$route.query.code;
        this.getInfo();
        // this.email();


    },



    methods: {
        onelineservice() {
            this.$router.push({
                path: "/chat", query: {
                    code: this.code,
                    channel: this.channel
                }
            })
        },


        was() {

            let link = "";
            let base = "https://api.whatsapp.com/send?phone="
            let urls = this.wa.toString().split(",");
            if (urls.length > 1) {
                let random = Math.abs(
                    Math.round(Math.random() * urls.length - 1)
                );
                console.log(urls[random], random, urls.length - 1);
                link = urls[random];
            } else {
                link = this.wa;
            }
            window.open(base + link + "&text=UID: " + this.code);
            //localStorage.setItem("url", this.url);
            //this.$router.push({ path: "/webview" });
        },





        getInfo() {
            this.get("user/service").then(res => {
                this.wa = res.data.wa;
                this.channel = res.data.tg
                if (this.channel.length > 2) {
                    this.dialogVisible = true
                }
            });
        },



    }
};
</script>

<style lang="scss" scoped>
.online-chat {
    padding: 2rem;

    .btnwa {
        text-align: center;
        background: #e1e3e6;
        color: #918f8f;
        padding: 1rem 0;
        border-radius: 2rem;
    }

    .content {
        padding: 2rem;
        color: #28baa6;
        line-height: 180%;
        font-size: 2rem;


    }
}

.service {

    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    justify-content: space-evenly;

    img {
        width: 8rem;
        height: 8rem;
    }

    padding-top: 2rem;
}

.contact {
    padding: 2rem;
    border-bottom: 1px solid #eaedf2;
}

.success {
    margin: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    justify-content: flex-start;


    padding: 2rem 2rem;
    text-align: center;
    color: #fff;
    border-radius: 1rem;

    i {
        font-style: normal;
        padding: 0.5rem;

        &:last-child {
            border: 1px solid #28baa6;
            color: #28baa6;
            padding: 0.5rem;
            border-radius: 0.5rem;
            margin-left: 2rem;
        }
    }

}

.safe {
    padding: 2rem;

    background: #fff;




}
</style>